<template>
  <v-container>
    <v-layout row wrap justify-space-between class="ma-2">
      <v-flex xs12 sm6 md6 lg6 class="text-left">
        <h1 class="title">{{ ownerDetails() }}</h1>
      </v-flex>
      <v-flex xs12 sm6 md6 lg6 class="text-right">
        <v-btn
          :color="
            button.selected
              ? $store.state.secondaryColor
              : $store.state.primaryColor
          "
          outlined
          class="mx-1 my-1"
          small
          v-for="button in menu"
          :key="button.title"
          @click="goTo(button.route)"
        >
          <v-icon small left>{{ button.icon }}</v-icon>
          <span class="caption text-capitalize">{{ button.title }}</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-divider class="my-3"></v-divider>
    <router-view></router-view>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
export default {
  data: () => ({
    titleToggle: false,
    ownerData: "null",
  }),

  created() {
    this.getOwnerDetails();
  },
  computed: {
    menu() {
      return [
        {
          icon: "mdi-account-plus",
          title: this.$t("add new shop"),
          route: "add",
          selected: true,
        },
        {
          icon: "mdi-file-document-edit",
          title: this.$t("client profile"),
          route: "",
          selected: false,
        },
      ];
    },
  },

  methods: {
    async getOwnerDetails() {
      const doc = await db
        .collection("owners")
        .doc(this.$route.params.id)
        .get();
      this.ownerData = {
        id: doc.id,
        ...doc.data(),
      };
    },

    ownerDetails() {
      return (
        this.ownerData.names.first +
        " " +
        this.ownerData.names.middle.substr(0, 1) +
        ". " +
        this.ownerData.names.last +
        " : " +
        this.ownerData.id
      );
    },

    goTo(route) {
      for (var i = 0; i < this.menu.length; i++) {
        this.menu[i].selected = false;
      }
      var index = this.menu.findIndex((x) => x.route == route);
      this.menu[index].selected = true;

      if (index == 0) {
        this.titleToggle = !this.titleToggle;
        this.menu[0].icon = this.titleToggle
          ? "fas fa-users"
          : "mdi-account-plus";
        this.menu[0].title = this.titleToggle ? "Shops" : "Add new Shop";
        this.menu[0].route = this.titleToggle ? "" : "add";
        this.$router.push({
          name: "AddStore",
          params: { id: this.$route.params.id },
        });
      } else {
        this.$router.push({
          name: "profileOwner",
          params: { id: this.$route.params.id },
        });
      }
    },
  },
};
</script>

<style scoped></style>
